import React from 'react'

import '@styleGlobals/globals.css'
import './layout.css'

import mapBody from '@images/map-body.png'
import mapBodyTablet from '@images/map-body-tablet.png'
import mapBodyResponsive from '@images/map-body-responsive.png'

import { LayoutProps } from './LayoutProps'

const Layout = ({ children }: LayoutProps) => {
  return (
    <main className="layout" id="layout">
      <div className="layout__background">
        <picture>
          <source srcSet={mapBodyResponsive} type="image/png" media="(max-width: 765px)" />
          <source srcSet={mapBodyTablet} type="image/png" media="(min-width: 766px) and (max-width: 1200px)" />
          <img src={mapBody} className="layout__background_src" alt="" />
        </picture>
      </div>
      {children}
    </main>
  )
}

export default Layout;
