exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-accounts-index-tsx": () => import("./../../../src/pages/accounts/index.tsx" /* webpackChunkName: "component---src-pages-accounts-index-tsx" */),
  "component---src-pages-aml-policies-index-tsx": () => import("./../../../src/pages/aml-policies/index.tsx" /* webpackChunkName: "component---src-pages-aml-policies-index-tsx" */),
  "component---src-pages-bonus-policies-index-tsx": () => import("./../../../src/pages/bonus-policies/index.tsx" /* webpackChunkName: "component---src-pages-bonus-policies-index-tsx" */),
  "component---src-pages-commodities-index-tsx": () => import("./../../../src/pages/commodities/index.tsx" /* webpackChunkName: "component---src-pages-commodities-index-tsx" */),
  "component---src-pages-cookies-policies-index-tsx": () => import("./../../../src/pages/cookies-policies/index.tsx" /* webpackChunkName: "component---src-pages-cookies-policies-index-tsx" */),
  "component---src-pages-cryptocurrencies-index-tsx": () => import("./../../../src/pages/cryptocurrencies/index.tsx" /* webpackChunkName: "component---src-pages-cryptocurrencies-index-tsx" */),
  "component---src-pages-forex-index-tsx": () => import("./../../../src/pages/forex/index.tsx" /* webpackChunkName: "component---src-pages-forex-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-metal-index-tsx": () => import("./../../../src/pages/metal/index.tsx" /* webpackChunkName: "component---src-pages-metal-index-tsx" */),
  "component---src-pages-policy-kyc-index-tsx": () => import("./../../../src/pages/policy-kyc/index.tsx" /* webpackChunkName: "component---src-pages-policy-kyc-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-risk-disclosure-policies-index-tsx": () => import("./../../../src/pages/risk-disclosure-policies/index.tsx" /* webpackChunkName: "component---src-pages-risk-disclosure-policies-index-tsx" */),
  "component---src-pages-s-index-index-tsx": () => import("./../../../src/pages/s-index/index.tsx" /* webpackChunkName: "component---src-pages-s-index-index-tsx" */),
  "component---src-pages-stock-index-tsx": () => import("./../../../src/pages/stock/index.tsx" /* webpackChunkName: "component---src-pages-stock-index-tsx" */),
  "component---src-pages-terms-and-conditions-index-tsx": () => import("./../../../src/pages/terms-and-conditions/index.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-tsx" */),
  "component---src-pages-webtrader-index-tsx": () => import("./../../../src/pages/webtrader/index.tsx" /* webpackChunkName: "component---src-pages-webtrader-index-tsx" */),
  "component---src-pages-withdrawal-policies-index-tsx": () => import("./../../../src/pages/withdrawal-policies/index.tsx" /* webpackChunkName: "component---src-pages-withdrawal-policies-index-tsx" */)
}

